<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col>
        <btn-adicionar
          v-if="$temPermissao('ponto-captura-insercao')"
          to="/pontos-captura/adicionar"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="$temPermissao('ponto-captura-listagem')"
      class="mt-4"
    >
      <v-col>
        <v-sheet elevation="1">
          <v-data-table
            disable-filtering
            disable-sort
            :server-items-length="totalRegistros"
            :loading="carregando"
            :headers="cabecalho"
            :items="pontosCaptura"
            :options.sync="paginacao"
            :footer-props="{
              'items-per-page-options': [10, 20, 50]
            }"
            @update:options="carregarPontosCaptura"
          >
            <template v-slot:[`item.status`]="{ item }">
              {{ item.status ? 'Ativo' : 'Inativo' }}
            </template>

            <template
              v-slot:[`item.actions`]="{ item }"
              class="text-right"
            >
              <table-btn-editar
                v-if="$temPermissao('ponto-captura-visualizacao')"
                :href="`/pontos-captura/${item.id}/editar`"
              />
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import pontosCaptura from '@/api/pontos-captura'

  export default {
    data () {
      return {
        carregando: false,
        carregandoDeletar: false,
        dialogDeletar: false,
        totalRegistros: 0,
        cabecalho: [
          { text: '#', value: 'id' },
          { text: 'Descrição', value: 'descricao' },
          { text: 'Status', value: 'status' },
          { text: 'Ações', value: 'actions', align: 'right' },
        ],
        pontosCaptura: [],
        paginacao: {},
      }
    },

    methods: {
      async carregarPontosCaptura () {
        try {
          this.carregando = true

          const resposta = await pontosCaptura.listar({
            pagina: this.paginacao.page,
            por_pagina: this.paginacao.itemsPerPage,
          })

          this.pontosCaptura = resposta.data.data
          this.totalRegistros = resposta.data.meta.total
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      },

    },

  }
</script>
